<template>
  <div
    v-if="isFormReady"
    class="absolute bottom-5 top-0 left-0 z-30 bg-white shadow grid grid-cols-2 pr-4 pl-6 py-5 pb-8 w-full overflow-y-auto"
    style="grid-template-rows: auto auto auto auto 1fr"
  >
    <div
      class="text-darkblue col-start-1 col-end-2 text-xl font-bold whitespace-no-wrap font-SourceSansPro"
    >
      {{ title === 1 ? $t('add-retranslators') : $t('edit-retranslators') }}
    </div>
    <div class="flex justify-end col-start-2 items-center">
      <i
        class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        @click="$emit('close')"
      />
    </div>
    <!-- Добавление ретранслятора-->
    <span v-if="title === 1" class="flex flex-col col-start-1 col-end-3">
      <div class="mt-8 flex col-start-1 col-end-3 items-center justify-between">
        <div class="flex flex-col w-full">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('companies') }}
          </p>
          <div class="flex items-center justify-between">
            <el-select
              v-model="retranslator.company_id"
              class="mr-4"
              filterable="filterable"
            >
              <el-option
                style="max-width: 265px"
                v-for="item in companies"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
            <div class="flex justify-end">
              <skif-button @click="createRetranslator">
                {{ $t('btn.save') }}
              </skif-button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col mt-6 col-start-1 col-end-3">
        <div class="flex flex-col mt-3">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('objects.imei') }}
          </p>
          <el-input v-model="retranslator.imei" class="w-1/2" type="number" />
        </div>
        <div class="flex flex-col mt-3">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.changeIMEI') }}
          </p>
          <el-input
            v-model="retranslator.to_imei"
            class="w-1/2"
            type="number"
          />
        </div>
        <div class="flex flex-col mt-3">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.protocol') }}
          </p>
          <el-select
            v-model="retranslator.retrans_protocol"
            class="mr-4 w-1/2"
            filterable="filterable"
            @change="handleRetransProtocolChange"
          >
            <el-option
              v-for="(protocol, index) in protocols"
              :key="index"
              :value="protocol.key"
              :label="protocol.value"
            />
          </el-select>
        </div>
        <!-- Новые поля для ретрансляции Yandex Transport -->
        <div
          v-if="retranslator.retrans_protocol === 'ya_transport'"
          class="flex flex-col mt-3"
        >
          <div class="mb-4 mt-2">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
            >
              {{ $t('retranslatorTranlsater.participant') }}
            </p>
            <el-input
              v-model="retranslator.member_name"
              class="w-1/2"
              maxlength="215"
            />
          </div>
          <div class="mb-4">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
            >
              {{ $t('retranslatorTranlsater.route_identifier') }}
            </p>
            <el-input
              v-model="retranslator.route_name"
              class="w-1/2"
              maxlength="100"
            />
          </div>
          <div class="mb-4">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
            >
              {{ $t('retranslatorTranlsater.gps_signal_category') }}
            </p>
            <div class="flex flex-col">
              <el-select v-model="gps_category_type_key" class="w-1/2">
                <el-option
                  v-for="(category, index) in gps_category_types"
                  :key="index"
                  :value="category.key"
                  :label="category.value"
                />
              </el-select>
              <span
                v-if="
                  retranslator.gps_category_type_key === 'gps_category_type_s'
                "
                class="text-grey-200 text-xs w-1/2 mt-1"
              >
                {{ $t('retranslatorTranlsater.hint') }}
              </span>
            </div>
          </div>

          <div class="mb-4">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
            >
              {{ $t('retranslatorTranlsater.type_vehicle') }}
            </p>
            <el-select v-model="public_vehicle_type_key" class="w-1/2">
              <el-option
                v-for="(vehicle, index) in public_vehicle_types"
                :key="index"
                :value="vehicle.key"
                :label="vehicle.value"
              />
            </el-select>
          </div>
        </div>

        <div class="flex flex-col mt-6">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.serverRetranslation') }}
          </p>
          <div class="flex justify-between">
            <el-input
              v-model="retranslator.retrans_server"
              class="w-1/2"
              auto-complete="off"
            />
          </div>
        </div>
        <div class="flex flex-col mt-6">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.port') }}
          </p>
          <div class="flex justify-between">
            <el-input
              v-model="retranslator.retrans_port"
              class="w-1/2"
              auto-complete="off"
            />
          </div>
        </div>
        <div class="flex flex-col mt-6">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.retranslationFrom') }}
          </p>
          <div class="flex justify-between">
            <el-date-picker
              v-model="retranslator.retrans_from"
              class="w-1/3"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :clearable="false"
              :placeholder="$t('reports.not-select')"
            />
          </div>
        </div>
        <div class="flex flex-col mt-6">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.retranslationTo') }}
          </p>
          <div class="flex justify-between"></div>
          <el-date-picker
            v-model="retranslator.retrans_to"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :clearable="false"
            :placeholder="$t('reports.not-select')"
          />
        </div>
      </div>
    </span>
    <!-- Редактирование ретранслятора-->
    <span v-else class="flex flex-col col-start-1 col-end-3">
      <div class="mt-8 flex col-start-1 col-end-3 items-center justify-between">
        <div class="flex flex-col w-full">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('companies') }}
          </p>
          <div class="flex items-center justify-between">
            <el-input
              v-model="item.company_name"
              class="w-1/2"
              type="text"
              disabled="disabled"
            />
            <div class="flex justify-end">
              <skif-button @click="editRetranslators">
                {{ $t('btn.save') }}
              </skif-button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col mt-6 col-start-1 col-end-3">
        <div class="flex flex-col mt-3">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('objects.imei') }}
          </p>
          <el-select
            v-model="retranslator.imei"
            class="mr-4 w-1/2"
            filterable="filterable"
          >
            <el-option
              v-for="item in imeis"
              :key="item.id"
              :value="item.imei"
              :label="item.imei"
            />
          </el-select>
        </div>
        <div class="flex flex-col mt-3">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.changeIMEI') }}
          </p>
          <el-input
            v-model="retranslator.to_imei"
            class="w-1/2"
            type="number"
          />
        </div>
        <div class="flex flex-col mt-3">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.protocol') }}
          </p>
          <el-select
            v-model="retranslator.retrans_protocol"
            class="mr-4 w-1/2"
            filterable="filterable"
            @change="handleRetransProtocolChange"
          >
            <el-option
              v-for="(protocol, index) in protocols"
              :key="index"
              :value="protocol.key"
              :label="protocol.value"
            />
          </el-select>
        </div>
        <!-- edit   -->
        <div
          v-if="retranslator.retrans_protocol === 'ya_transport'"
          class="flex flex-col mt-3"
        >
          <div class="mb-4 mt-2">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
            >
              <!-- Идентификатор участника программы: -->
              {{ $t('retranslatorTranlsater.participant') }}
            </p>
            <el-input
              v-model="retranslator.member_name"
              class="w-1/2"
              maxlength="215"
            />
          </div>
          <div class="mb-4">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
            >
              {{ $t('retranslatorTranlsater.route_identifier') }}
            </p>
            <el-input
              v-model="retranslator.route_name"
              class="w-1/2"
              maxlength="100"
            />
          </div>
          <div class="mb-4">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
            >
              {{ $t('retranslatorTranlsater.gps_signal_category') }}
            </p>
            <div class="flex flex-col">
              <el-select v-model="gps_category_type_key" class="w-1/2">
                <el-option
                  v-for="(category, index) in gps_category_types"
                  :key="index"
                  :value="category.key"
                  :label="category.value"
                />
              </el-select>
              <span
                v-if="gps_category_type_key === 'gps_category_type_s'"
                class="text-grey-200 text-xs w-1/2 mt-1"
              >
                {{ $t('retranslatorTranlsater.hint') }}
              </span>
            </div>
          </div>

          <div class="mb-4">
            <p
              class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
            >
              {{ $t('retranslatorTranlsater.type_vehicle') }}
            </p>
            <el-select v-model="public_vehicle_type_key" class="w-1/2">
              <el-option
                v-for="(vehicle, index) in public_vehicle_types"
                :key="index"
                :value="vehicle.key"
                :label="vehicle.value"
              />
            </el-select>
          </div>
        </div>

        <div class="flex flex-col mt-6">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.serverRetranslation') }}
          </p>
          <div class="flex justify-between">
            <el-input
              v-model="retranslator.retrans_server"
              class="w-1/2"
              auto-complete="off"
            />
          </div>
        </div>
        <div class="flex flex-col mt-6">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.port') }}
          </p>
          <div class="flex justify-between">
            <el-input
              v-model="retranslator.retrans_port"
              class="w-1/2"
              auto-complete="off"
            />
          </div>
        </div>
        <div class="flex flex-col mt-6">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.retranslationFrom') }}
          </p>
          <div class="flex justify-between">
            <el-date-picker
              v-model="retranslator.retrans_from"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :clearable="false"
              :placeholder="$t('reports.not-select')"
            />
          </div>
        </div>
        <div class="flex flex-col mt-6">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('retranslatorTranlsater.retranslationTo') }}
          </p>
          <div class="flex justify-between"></div>
          <el-date-picker
            v-model="retranslator.retrans_to"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :clearable="false"
            :placeholder="$t('reports.not-select')"
          />
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { adminPanel } from '@/api'

export default {
  name: 'Retranslators',
  props: {
    title: Number,
    item: {
      type: Object,
      default: () => {}
    },
    imeis: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      retranslator: {},
      isFormReady: false,
      public_vehicle_type_key: '',
      gps_category_type_key: ''
    }
  },

  computed: {
    ...mapGetters({
      companies: 'login/companies',
      protocols: 'dictionary/protocol',
      gps_category_types: 'dictionary/gps_category_type',
      public_vehicle_types: 'dictionary/public_vehicle_type'
    }),
    ...mapGetters('login', ['companies'])
  },
  watch: {
    'retranslator.retrans_protocol': function (val) {
      console.log('changed', val)
    }
  },

  methods: {
    handleRetransProtocolChange() {
      if (this.retranslator.retrans_protocol === 'ya_transport') {
        this.public_vehicle_type_key = this.public_vehicle_types[0].key
        this.gps_category_type_key = this.gps_category_types[0].key

        this.retranslator.retrans_server = 'extjams.maps.yandex.net'
        this.retranslator.retrans_port = '80'
      } else {
        this.retranslator.retrans_server = ''
        this.retranslator.retrans_port = ''
      }
    },

    async saveTerminal() {
      // this.public_vehicle_type_key = this.public_vehicle_types[0].key
      // this.gps_category_type_key = this.gps_category_types[0].key
      const data = {
        id: this.item.id,
        terminal: {
          imei: this.adminImei,
          company_id: this.company,
          password: this.password,
          is_blocked: this.activity
        }
      }
      await adminPanel.putTerminals(
        data,
        (response) => {
          this.$emit('close', response)
          this.companies.pop()
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    },
    async editRetranslators() {
      const currentCompany = this.companies.find(
        (company) => company.id === this.retranslator.company_id
      )

      await adminPanel.editRetranslator(
        this.retranslator,
        (response) => {
          this.$emit('close', {
            ...response.data,
            company_name: currentCompany.name
          })
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    },
    async createRetranslator() {
      const currentCompany = this.companies.find(
        (company) => company.id === this.retranslator.company_id
      )

      const formData = {
        ...this.retranslator,
        public_vehicle_type_key: this.public_vehicle_type_key,
        gps_category_type_key: this.gps_category_type_key
      }
      // if (this.retranslator.retrans_protocol === 'ya_transport') {
      //   if (
      //     !this.retranslator.retrans_server ||
      //     this.retranslator.retrans_server === ''
      //   ) {
      //     formData.retrans_server = 'extjams.maps.yandex.net'
      //   }
      //   if (
      //     !this.retranslator.retrans_port ||
      //     this.retranslator.retrans_port === ''
      //   ) {
      //     formData.retrans_port = 80
      //   }
      // }

      await adminPanel.addRetranslator(
        formData,
        (response) => {
          this.$emit('close', {
            ...response.data,
            company_name: currentCompany.name
          })
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    }
  },

  async mounted() {
    this.title === 2
      ? await adminPanel.getRetranslator(
          this.item.id,
          (response) => {
            const item = response.data

            this.companies.forEach((val) => {
              if (val.name === this.item.company_name) {
                this.company = val.id
              }
            })
            if (!this.company) {
              this.companies.push({
                id: this.item.company_id,
                name: this.item.company_name
              })
              this.company = this.item.company_id
            }
            this.retranslator = item

            this.isFormReady = true
          },
          (error) => {
            this.$showError(error.response.data.message)
          }
        )
      : ((this.retranslator = {
          retrans_protocol: null,
          retrans_server: null,
          retrans_port: null
        }),
        (this.isFormReady = true))
  }
}
</script>
